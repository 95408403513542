/* Styles for mobile devices */
@media only screen and (max-width: 767px) {
  /* CSS rules for mobile devices */
  .App {
    .newMuiContainer-root {
      display: flex;
      width: 100vw;
      flex-grow: 1;
    }

    .newDSMuiContainer-root {
      width: 100%;
    }

    .newDSMuiContainer-root-application,
    .newDSMuiContainer-root-application-left-nav-open {
      width: 100vw;
    }

    .main-content {
      display: flex;
      flex-grow: 1;
      max-width: 100vw;
      margin-top: 16px;

      .newMuiContainer-root {
        .chat-panel {
          margin-left: 0%;
        }
      }
    }
  }
}

/* Styles for tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* CSS rules for tablets */
  .App {
    .newMuiContainer-root {
      display: flex;
      max-width: 100%;
      width: 100%;
      flex-grow: 1;
    }

    .newDSMuiContainer-root {
      max-width: 100%;
      width: 100%;
    }

    .newDSMuiContainer-root-application {
      max-width: calc(100vw - 60px);
      width: calc(100vw - 60px);
    }

    .newDSMuiContainer-root-application-left-nav-open {
      max-width: calc(100vw - 240px);
      width: calc(100vw - 240px);
    }

    .main-content {
      width: 100%;
      display: flex;
      // margin-top: 16px;

      .newMuiContainer-root {
        .chat-panel {
          width: 20%;
          min-width: 350px;
          margin-left: calc(50% - 175px);
        }
      }
    }
  }
}

/* Styles for desktops */
@media only screen and (min-width: 992px) {
  /* CSS rules for desktops */
  .App {
    display: flex;

    .MuiContainer-root {
      margin: 0;
      max-width: 100%;
    }

    .login-container {
      justify-content: 'center';
      display: 'flex';
      flex-flow: 'column';
      max-width: 70vw;
    }

    .newMuiContainer-root {
      display: flex;
      max-width: 100%;
      width: 100%;
      flex-grow: 1;
    }

    .newDSMuiContainer-root {
      max-width: 100%;
      width: 100%;
    }

    .newDSMuiContainer-root-application {
      max-width: calc(100vw - 60px);
      width: calc(100vw - 60px);
    }

    .newDSMuiContainer-root-application-left-nav-open {
      max-width: calc(100vw - 240px);
      width: calc(100vw - 240px);
    }

    .main-content {
      padding-top: 16px;
      width: 100%;
      display: flex;
      // margin-top: 16px;

      .newMuiContainer-root {
        .chat-panel {
          width: 20%;
          min-width: 350px;
          margin-left: calc(50% - 175px);
        }
      }
    }
  }
}

.bookmark-menu
  > div.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
}

.MuiTooltip-popper {
  pointer-events: all !important;
}

span {
  font-size: 0.9rem;
}

.App {
  color: #333;
  font-size: 0.9rem;

  .frame {
    height: 26.95px;
    position: relative;
  }

  .newDSMuiContainer-root-insights-left-nav-open-info-pane-open {
    padding-right: 10px;
    max-width: 100%;
    width: calc(calc(100vw - 240px) - calc((100vw - 240px) * 0.4));

    .insights-details {
      max-width: calc(calc(100vw - 240px) - calc((100vw - 240px) * 0.4));
      width: calc(calc(100vw - 240px) - calc((100vw - 240px) * 0.4));
    }
  }

  .newDSMuiContainer-root-insights-left-nav-open-info-pane-close {
    padding-right: 10px;
    max-width: 100%;
    width: calc(100vw - 240px);

    .insights-details {
      max-width: calc(100vw - 240px);
      width: calc(100vw - 240px);
    }
  }

  .newDSMuiContainer-root-insights-left-nav-close-info-pane-open {
    padding-right: 10px;
    max-width: 100%;
    width: calc((100vw - 60px) - calc((100vw - 240px) * 0.4));

    .insights-details {
      max-width: calc((100vw - 60px) - calc((100vw - 240px) * 0.4));
      width: calc((100vw - 60px) - calc((100vw - 240px) * 0.4));
    }
  }

  .newDSMuiContainer-root-insights-left-nav-close-info-pane-close {
    padding-right: 10px;
    max-width: 100%;
    width: calc(100vw - 80px);

    .insights-details {
      max-width: calc(100vw - 80px);
      width: calc(100vw - 80px);
    }
  }

  .info-drawer-close-button {
    background: transparent;
    z-index: 10;
    float: left;
    position: relative;
    font-size: 20px;

    &:hover {
      background-color: #eee;
    }
  }

  /* CSS */
  .drawer-close-button {
    font-size: 20px;
    background: #eee;
    width: 40px;
    float: right;
    right: -9px;
    border-radius: 10px;
    position: absolute;
    margin-top: 8px;

    &:hover {
      background-color: #eee;
    }
  }

  .drawer-open-button {
    font-size: 20px;
    background: #eee;
    width: 40px;
    float: left;
    right: -7px;
    margin-top: 8px;

    &:hover {
      background-color: #eee;
    }
  }

  .MuiListItemIcon-root {
    min-width: 30px;
    font-size: 20px;
    color: rgb(65, 67, 70);
  }

  .MuiButton-root {
    font-size: 1rem;
    text-transform: none;
    border-radius: 10px;
    box-shadow: none;
    color: #222;
    background-color: transparent;
    border: solid 1.5px #222;
    padding: 2px 10px;

    &:hover {
      background-color: transparent;
      box-shadow: none;
      text-decoration: underline;
    }
  }

  .MuiSelect-select,
  .MuiOutlinedInput-input,
  .MuiMenuItem-root,
  .MuiTypography-root {
    font-size: 0.9rem;
  }

  .login-logoContainer,
  .logoContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
  }

  .logoContainer {
    margin: 8px;
  }

  .logoImage {
    height: 30px;
    width: 30px;
  }

  .logoText {
    font-weight: 800;
    font-size: 25px;
    text-decoration: none;
    font-weight: bold;
    margin-left: 10px;
  }

  .beta {
    font-size: 0.7rem;
    font-weight: 300;
    color: white;
    margin-left: 7px;
    transform: rotate(-10deg) translateY(-10px);
    border: solid 1px #5d59ff;
    color: #5d59ff;
    padding: 0 3px;
    border-radius: 3px;
  }

  .try-it {
    padding: 5px;
    border-radius: 4px;
    border-color: #444;
    background-color: #444;
    color: white;
  }

  .waitlist-helper-text {
    background: rgb(238, 238, 238);
    color: darkslategrey;
    font-size: 0.9rem;
    padding: 10px;
    border-radius: 10px;
    transform: translateX(-15px);
    width: calc(100% + 10px);
  }

  .login-error-helper-text {
    background: rgb(238, 238, 238);
    color: darkred;
    font-size: 0.9rem;
    padding: 10px;
    border-radius: 10px;
    transform: translateX(-15px);
    width: calc(100% + 10px);
  }

  .login-success-helper-text {
    background: #495057;
    color: white;
    font-size: 0.9rem;
    padding: 10px;
    border-radius: 10px;
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
    width: calc(100% + 10px);
    font-style: oblique;
  }

  .login-waitlist-helper-text {
    color: #495057;
    font-size: 1.2rem;
    line-height: 2.25rem;
    padding: 10px;
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
    width: calc(100% + 10px);
    font-weight: 200;
  }

  .cancelButton {
    border: none;
  }

  .createButton {
    text-decoration: none;
    border-color: #5d59ff;
    color: #5d59ff;

    &:hover {
      text-decoration: none;
      background-color: #5d59ff;
      color: white;
      border-color: #5d59ff;
    }
  }

  .deleteButton {
    text-decoration: none;
    border-color: rgba(150, 13, 47, 0.9);
    color: rgba(150, 13, 47, 0.9);

    &:hover {
      text-decoration: none;
      background-color: rgb(150, 13, 47);
      color: white;
    }
  }

  .suggest-button {
    text-decoration: none;
    border-color: #222;
    color: #222;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 5px;
    font-size: 0.8rem;
    font-weight: 440;
    max-width: 80%;
    white-space: break-spaces;

    &:hover {
      text-decoration: none;
      background-color: #222;
      color: white;
    }
  }

  .loginButton,
  .upgradeButton {
    text-transform: none;
    border-color: #5d59ff;
    background-color: #5d59ff;
    color: white;
    padding: 5px;
    height: 40px;
    font-size: 0.9em;
    font-weight: 500;
    border-radius: 20px;

    width: 150px;
    align-self: center;

    &:hover {
      text-decoration: none;
      border-color: #1e1bcf;
      background-color: #1e1bcf;
    }
  }

  .upgradeButton {
    width: 200px;
  }

  .accountButton {
    text-transform: none;
    border: none;
    padding: 5px 0;
    font-size: 0.9rem;
    color: #555;
    width: 23px;
    min-width: 23px;
  }

  .flyout {
    margin-top: 2px;
  }

  .accountSettingsItem {
    cursor: pointer;
  }

  .logoutItem {
    cursor: pointer;
  }

  .status-pill {
    height: 20px;
    color: white;
    float: right;
  }

  .status-pill > .MuiChip-label {
    font-weight: bold;
    font-size: 0.6rem;
    text-transform: capitalize;
  }

  .status-pill.status-pending {
    background-color: #ffc107;
  }

  .status-pill.status-in-progress {
    background-color: #2196f3;
  }

  .status-pill.status-completed {
    background-color: #4caf50;
  }

  .MuiPaper-root {
    box-shadow: none;
    overflow: visible;
  }

  .fileUpload {
    margin-top: 16px;
    margin-left: 24px;
    margin-bottom: 24px;
    border: dotted 1px #999;
    padding: 20px 30px;
    border-radius: 20px;
  }

  .MuiCardContent-root {
    border: solid 1px rgb(231, 231, 251);
    border-radius: 0.75rem;

    &:hover {
      box-shadow: 0 0 5px 1px #f0f0f0;
      cursor: pointer;
    }
  }

  .MuiInputBase-root,
  .MuiInputBase-input {
    padding: 5px;

    .MuiIconButton-edgeEnd {
      margin-right: 0px;
    }
  }

  .external-link {
    color: #5d59ff;
    text-decoration: none;
  }

  .right-info-pane,
  .main-content {
    .newMuiContainer-root {
      display: flex;
      flex-grow: 1;

      .MuiGrid-root {
        flex-flow: column;
      }

      .MuiGrid-item {
        flex-basis: 0;
      }

      .vertical-tabs-flexFlow {
        flex-flow: row;
        padding-left: 0px;
      }

      .vertical-tabs {
        padding-top: 5px;
        /* padding-bottom: Update ContainerBox as well */
        padding-bottom: 20px;
        flex-grow: 1;
        flex-basis: 0;
        display: flex;

        > .MuiBox-root {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          flex-basis: 0;
        }
      }

      .vertical-tabs-api {
        margin-top: 5px;
        padding-top: 0;
        padding-left: 0;
        flex-grow: 1;
        display: flex;
        /* height: 100%; */
        /* background-color: rgba(242, 244, 245, 0.2); */
      }

      .vertical-tabs-tabs {
        background-color: rgba(242, 244, 245, 0.5);
        min-height: 35px;
      }

      .chat-panel {
        box-shadow: 0 0 10px #eee;
        padding: 0 10px;
        border-radius: 10px;

        .question-box {
          max-width: 90%;
        }

        .answer-box {
          max-width: 90%;

          .markdown {
            a {
              color: #2196f3;
            }
          }
        }
      }

      .api-tab-panel {
        flex-grow: 1;

        > .MuiBox-root {
          padding: 0px;
          padding-left: 10px;
        }
      }

      .chat-tab-panel {
        flex-grow: 1;
        background-color: rgba(149, 121, 226, 0.02);
        // .MuiBox-root {
        //     padding: 0px;
        // }
      }

      .api-tab-button {
        min-width: 20px;
        min-height: 20px;
        font-size: 1.1rem;
        padding: 5px;
        margin: 12px;
        margin-top: 0;
        border-radius: 4px;

        &.Mui-selected {
          background-color: #eee;
          border: none;
        }
      }

      .chat-tab-button {
        min-height: 20px;
      }

      .chat-tab-button.MuiTab-root.Mui-selected {
        font-weight: 600;
        color: White;
        background-color: #999;
      }
    }

    .MuiGrid-container {
      display: flex;
      /* height: 100%; */
    }

    .MuiFormLabel-root {
      font-size: 0.9rem;
    }

    .wrk-panel {
      background-color: white;

      .MuiBox-root.editor-options-box {
        box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.05);
      }

      .custom-system-prompt {
        margin-bottom: 10px;

        .MuiOutlinedInput-input {
          height: 75px !important;
        }
      }

      a {
        color: #222;
        padding: 2px;
        text-decoration: none;
      }

      .container-box {
        overflow: auto;
        padding: 10px 0;
      }

      .message-container {
        text-align: left;
        line-height: 1.2rem;
        display: flex;
        flex-flow: column;
      }

      .question-box {
        border-radius: 15px;
        background-color: #eeee;
        font-size: 0.9rem;
        max-width: 40%;
        align-self: flex-end;
      }

      .answer-box {
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: #fafafa;
        border-radius: 10px;
        max-width: 60%;
        align-self: flex-start;
      }

      .feedback-buttons {
        display: flex;

        .MuiTypography-root {
          font-size: 0.75rem;
          color: #888;
        }

        justify-content: flex-end;
      }

      .suggest-buttons {
        padding: 10px;
        padding-right: 0px;
        margin-bottom: 10px;
        max-width: 90%;
        text-align: right;
        align-self: flex-end;
      }

      .suggest-button-base {
        /* Add your base suggest button styles here */
      }

      .suggest-button {
        /* Add your suggest button styles here */
      }

      .save-button {
        border: none;
        padding: 0;
        margin: 0;
        min-width: 30px;
        color: #555;
        cursor: pointer;

        .MuiSvgIcon-root {
          font-size: 23px;
          cursor: pointer;
        }

        .MuiButton-startIcon {
          margin: 10px 20px;
          cursor: pointer;
        }
      }
    }

    .header {
      font-size: 1.5rem;
      font-weight: 800;
    }

    .subheader {
      font-size: 1.2rem;
      font-weight: 500;
    }

    .subheader2 {
      font-size: 1.1rem;
      font-weight: 500;
    }

    .subheader-small {
      font-size: 0.9rem;
      font-weight: 400;
    }

    .chart-header {
      font-size: 0.9rem;
      font-weight: 700;
      text-align: center;
      padding: 0 0 5px 0;
      border-bottom: solid 1px #b6d3e8;
      display: inline-block;
      margin-left: 10px;
      margin-bottom: 10px;
      margin-top: 10px;

      small {
        font-weight: 500;
      }
    }

    .chart-subheader {
      font-size: 0.85rem;
      font-weight: 500;
      text-align: center;
      padding: 0 0 5px 0;
      border-bottom: solid 1px #b6d3e8;
      display: inline-block;
      margin-left: 10px;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .analytics-questions-list {
      height: inherit;
      margin: 10px;
      margin-bottom: 25px;
      padding: 10px;
      font-size: 0.9rem;
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;

      .analytics-questions-list-item {
        cursor: pointer;
        border: solid 1px #ccc;
        padding: 5px 20px;
        border-radius: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        background: #f7f7f7;
      }
    }

    .chart.custom-tooltip {
      display: block;
      font-size: 0.8rem;
      max-width: 200px;
      min-height: 20px;
      padding: 15px;
      white-space: break-spaces;
      word-wrap: break-word;
      background-color: #444;
      border-radius: 4px;
      color: white;
    }

    .subsubheader {
      border-radius: 10px;
      padding: 10px 10px;
      background-color: rgb(250, 250, 250);
      font-weight: 350;
      line-height: 1.5rem;
      text-transform: none;
    }

    .home-grid-item {
      padding-top: 0;
    }

    .home-button {
      border: none;
      color: rgba(5, 4, 81, 0.801);
      border: solid 1px rgba(5, 4, 81, 0.801);
      border-radius: 10px;
      font-size: 1rem;
      padding: 5px 10px;
      margin-right: 20px;
      margin-top: 10px;
      text-decoration: none;

      &:hover {
        color: #fefefe !important;
        background-color: #5d59ff;
        border-color: #5d59ff;
      }

      a {
        &:hover {
          color: #fefefe !important;
        }
      }
    }

    .card-title {
      font-size: 0.9rem;
      font-weight: 500;
    }

    .card-sub-title {
      font-size: 0.8rem;
      font-weight: 400;
      color: #555;
      text-transform: lowercase;
      font-style: italic;
    }

    .source-multiple-chip-parent {
      .MuiSvgIcon-root {
        display: none;
      }

      .Mui-focused .MuiOutlinedInput-notchedOutline {
        border: solid 1px #aaa;
      }
    }

    #source-multiple-chip {
      margin-right: 10px;
      min-width: 70px;
    }

    .chip {
      border-radius: 10px;
    }

    .swagger-ui .wrapper {
      margin: 0;
    }
  }

  .right-info-pane {
    .container-box {
      margin: 0 10px;
    }
  }

  .tox-tinymce {
    border: none;
  }

  .MuiFormHelperText-root {
    font-size: 0.7em;
    color: #777;
    margin-left: 2;
  }

  .empty-flex-container {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    margin-top: 10px;
  }

  .empty-flex-item {
    padding: 20px;
    border-radius: 10px;
    border: solid 1px #ddd;
    width: 240px;
    height: 200px;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .zero-top-padding {
    padding-top: 0px;
  }

  .width-100-percent {
    width: 100%;
  }

  .padding-0 {
    padding: 0px;
    padding-left: 5px;
  }

  .top-padding-30 {
    padding-top: 30px;
  }

  .top-padding-10 {
    padding-top: 10px;
  }

  .top-bottom-padding-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .left-padding-10 {
    padding-left: 10px;
  }

  .bottom-padding-10 {
    padding-bottom: 10px;
  }

  .bottom-padding-5 {
    padding-bottom: 5px;
  }

  .font-size-point8 {
    font-size: 0.85rem;
  }

  .feedback-btn {
    font-size: 0.8rem;
    text-transform: none;
    box-shadow: none;
    color: #999;
    background-color: transparent;
    border: none;
    padding: 5px;
    min-width: 30px;

    &:hover {
      background-color: #bbb;
      color: #fefefe;
      box-shadow: none;
      text-decoration: underline;
    }
  }

  .markdown {
    padding: 0 10px;
    white-space: normal;
  }

  .copyAll > .MuiSvgIcon-root {
    font-size: 0.8rem;
  }

  ::placeholder {
    font-size: 0.9rem;
  }

  .MuiTabs-indicator {
    display: none;
  }

  .display-none {
    display: none;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .analytics-conversation {
    padding: 0 30px;

    .message-container {
      text-align: left;
      line-height: 1.2rem;
      display: flex;
      flex-flow: column;
    }

    .question-box {
      padding: 5px;
      background-color: #eee;
      font-size: 0.9rem;
      display: flex;
      flex-flow: row;
    }

    .answer-box {
      padding: 5px;
      background-color: #fafafa;
      display: flex;
      flex-flow: row;
    }

    .improvements-box {
      padding: 5px;
      margin-left: 100px;
      margin-top: 5px;
      margin-bottom: 5px;
      border: solid 0.15rem rgb(235, 194, 155);
      // background-color: rgb(235, 194, 155);
      border-radius: 10px;
    }

    .improvements-box-why {
      padding: 5px;
      margin-left: 100px;
      margin-bottom: 5px;
      border: solid 0.15rem #b6d3e8;
      // background-color: #b6d3e8;
      border-radius: 10px;
    }

    .improvements-box-ideal-response {
      padding: 5px;
      margin-left: 100px;
      margin-bottom: 5px;
      border: solid 0.15rem #aebba3;
      // background-color: #b6d3e8;
      border-radius: 10px;
    }
  }
}

a {
  text-decoration: none;
  color: #222;
}

.markdown * {
  font-size: 0.85rem;
}

.markdown img {
  max-width: 300px;
}

.historical-insights {
  width: 100%;

  .markdown tr {
    border-top: #333;
  }

  .markdown tr {
    text-align: left;
  }

  .markdown td {
    padding: 5px;
    text-align: left;
    outline: dotted 1px #777;
  }

  .markdown ul {
    padding-inline-start: 1rem !important;
  }

  .markdown li {
    margin: 10px;
  }

  .markdown p {
    margin-block-start: 0.3em;
    margin-block-end: 0.3em;
  }

  .MuiTabs-flexContainer {
    justify-content: center;
  }

  .MuiTabs-root,
  .MuiTab-root,
  .MuiTab-root.Mui-selected {
    max-height: 30px;
    min-height: 0px;
    height: 30px;
    text-transform: capitalize;
  }

  .MuiTab-root.Mui-selected {
    color: White;
    background-color: #999;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    text-transform: capitalize;
  }

  /* CSS */
  .close-button {
    background: white;
    z-index: 10;
    position: absolute;
    font-size: 25px;
    transform: translateX(-35px) translateY(-10px);

    &:hover {
      background-color: #eee;
    }
  }

  .original-ticket {
    float: right;
    font-size: 11px;
    font-weight: 500;
    padding: 4px;
    border-radius: 4px;
    border: 1px dotted #aaa;
    margin: 5px 20px 0px 0px;
  }

  .ticket-analytics-panel {
    overflow: scroll;
    height: calc(100vh - 50px);
    color: black;
    width: calc((100vw - 240px) * 0.4);
    word-wrap: break-word;
    white-space: initial;
    border-top: solid 0.07rem #aaa;
  }
}

.App .right-info-pane .wrk-panel.wrk-chat-panel .question-box {
  max-width: 40%;
  align-self: flex-end;
}

.App .right-info-pane .wrk-panel.wrk-chat-panel .answer-box {
  max-width: 60%;
  align-self: flex-start;
}

.App .right-info-pane .wrk-panel .question-box,
.App .right-info-pane .wrk-panel .answer-box {
  padding: 0;
  border-radius: 10px;
  max-width: 100%;
  align-self: stretch;
}

.App .right-info-pane .wrk-panel .markdown {
  a {
    text-decoration: none;
    color: #2196f3;
  }
}

.listLink {
  display: block;
  height: 36px;
}

.listLink.taller {
  height: 42px;
}

li {
  font-size: 0.9rem;
}

/* Hide scrollbar for webkit-based browsers (Chrome, Safari) */
/* Note: This will only work for webkit-based browsers */
/* You can add these styles to a specific container or the body */

/* Hide scrollbar track */
::-webkit-scrollbar {
  width: 0em;
  /* Set a non-zero width to enable pseudo-element */
}

/* Hide scrollbar handle */
::-webkit-scrollbar-thumb {
  background-color: transparent;
}

// .MuiPaper-root {
//     scrollbar-width: none;
// }

/* Optional: If you want to customize the scrollbar further, you can add styles to the Firefox scrollbar thumb */
* {
  scrollbar-color: #888 transparent;
  /* Color of the thumb and track (in that order) */
}

/* If you want to keep the scrollbar on certain elements but not the body, you can target those elements specifically */
/* Example: */
/* .scrollable-container { */
/*   scrollbar-width: none; */
/* } */

/* GreetingAnimation.css (CSS stylesheet) */
.greeting-container {
  display: flex;
  justify-content: flex-start !important;
  flex-direction: column !important;
  align-items: center;
  height: 100vh;

  .welcome-banner {
    width: 100vw;
    padding: 10px;
    background-color: #5d59ff;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.greeting-animation {
  font-size: 24px;
  animation: fadeIn 1.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
