// Define variables for colors and other common properties
$primaryColor: #0d6efdc4; // rgba(61, 13, 150, 0.7);
$primaryColorHover: #0d6efdc4; //rgba(61, 13, 150, 1);
$boxShadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.03);
$borderRadius: 10px;

/* Styles for mobile devices */
@media only screen and (max-width: 767px) {
  /* CSS rules for mobile devices */
  .search-page-base {
    .search-results-box-base {
      .result-item-base {
        .question-box-base {
          max-width: 100%;
        }

        .answer-box {
          max-width: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  /* CSS rules for tablets */

  .search-page-base {
    .search-results-box-base {
      .result-item-base {
        .question-box-base {
          max-width: 50%;
        }

        .answer-box {
          max-width: 65%;
        }
      }
    }
  }
}

body {
  margin: 0;
}

.search-page-base {
  font-size: 0.85rem;
  width: 100%;
  height: 100%;

  .search-panel-base {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    // .MuiButton-root {
    //     font-size: 1rem;
    //     text-transform: none;
    //     border-radius: $borderRadius;
    //     box-shadow: none;
    //     color: #222;
    //     background-color: transparent;
    //     border: solid 1.5px #222;
    //     padding: 2px 10px;

    //     &:hover {
    //         background-color: transparent;
    //         box-shadow: none;
    //         text-decoration: underline;
    //     }
    // }

    .feedback-btn {
      font-size: 0.8rem;
      text-transform: none;
      box-shadow: none;
      color: #777;
      background-color: transparent;
      border: none;
      padding: 5px;
      min-width: 30px;

      &:hover {
        background-color: #bbb;
        color: #fefefe;
        box-shadow: none;
        text-decoration: underline;
      }

      .MuiSvgIcon-root {
        font-size: 15px;
        cursor: pointer;
      }
    }

    .input-box-base {
      -webkit-text-size-adjust: 100%;
      font-weight: normal;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      display: inline-block;
      line-height: 20px;
      vertical-align: middle;
      transition: opacity 0.8s ease-out;
      opacity: 0.5;
      background: white;
      border-radius: 4px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
      padding: 25px 11px;
      margin: 0px;
      font-size: 15px;
      height: 60px;
      color: #333;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.2);

      &:focus {
        opacity: 1;
        outline: none;
        -moz-box-shadow: $boxShadow;
        -webkit-box-shadow: $boxShadow;
        box-shadow: $boxShadow;
      }
    }

    .suggest-button-base,
    .reset-button-base {
      text-decoration: none;
      text-transform: none;
      font-size: 0.8rem;
      padding: 2px 10px;
      border-radius: $borderRadius;
      cursor: pointer;
      border: none;
    }

    .suggest-button-base {
      border: solid 1px $primaryColor;
      color: $primaryColor;
      margin-right: 10px;
      margin-bottom: 10px;
      margin-top: 5px;

      &:hover {
        background-color: $primaryColorHover;
        color: white;
      }
    }

    .reset-button-base {
      background-color: $primaryColor;
      margin-left: 10px;

      color: white;

      &:hover {
        background-color: $primaryColorHover;
      }
    }
  }

  .MuiContainer-root {
    padding: 0;
  }

  a {
    color: $primaryColor;
    padding: 2px;
    text-decoration: none;
  }

  .search-results-box-base {
    overflow: auto;
    padding: 10px 0;

    .result-item-base {
      text-align: left;
      line-height: 1.2rem;
      display: flex;
      flex-flow: column;

      .question-box-base,
      .answer-box {
        padding: 10px;
        border-radius: $borderRadius;
        margin-bottom: 20px;
        width: auto;
      }

      .question-box {
        background-color: #eeee;
        font-size: 0.9rem;
        align-self: flex-end;
      }

      .answer-box {
        background-color: #fafafa;
        align-self: flex-start;
      }

      .suggestions-box-base {
        padding: 10px;
        border-radius: $borderRadius;
        font-size: 0.9rem;
        width: auto;
        text-align: right;
      }

      .container-input-box-inline-base {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;

        .input-box-inline-base {
          -webkit-text-size-adjust: 100%;
          font-weight: normal;
          font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
          display: inline-block;
          line-height: 20px;
          vertical-align: middle;
          transition: opacity 0.8s ease-out;
          opacity: 0.5;
          background: white;
          border-radius: 5px;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
          padding: 23px 11px;
          margin: 0px;
          font-size: 15px;
          height: 40px;
          color: #333;
          box-sizing: border-box;
          border: 1px solid rgba(0, 0, 0, 0.25);

          &:focus {
            opacity: 1;
            outline: none;
            -moz-box-shadow: $boxShadow;
            -webkit-box-shadow: $boxShadow;
            box-shadow: $boxShadow;
          }
        }

        .reset-button-inline-base {
          text-transform: none;
          background-color: $primaryColor;
          margin-left: 10px;
          border: none;

          color: white;

          &:hover {
            background-color: $primaryColorHover;
          }
        }
      }
    }
  }
}
