body {
  margin: 0;
  overflow-x: clip;
}

.AppReamazeModule {
  overflow: scroll;
  font-size: 0.85rem;

  .MuiContainer-root {
    width: 100vw;
    padding: 0;
  }

  .wrk-panel {
    a {
      color: rgba(61, 13, 150, 0.7);
      padding: 2px;
      text-decoration: none;
    }

    .container-box {
      overflow: auto;
    }

    .message-container {
      text-align: left;
      line-height: 1.2rem;
      display: flex;
      flex-flow: column;
    }

    .markdown {
      padding: 0 10px;

      h4 {
        margin-block: 10px;
        font-weight: 400;
      }

      ol {
        padding-inline-start: 20px;
      }
    }

    .question-box {
      padding: 0;
      background-color: #def0f4;
      font-size: 0.9rem;
    }

    .answer-box {
      background-color: #fafafa;
    }

    .suggest-buttons {
      padding: 10px;
      margin-bottom: 10px;
      max-width: 70%;
      align-self: flex-end;
      display: none;
    }

    .suggest-button-base {
      /* Add your base suggest button styles here */
    }

    .suggest-button {
      /* Add your suggest button styles here */
    }

    .feedback-buttons {
      display: flex;
      justify-content: flex-end;

      .MuiButtonBase-root {
        min-width: 35px;

        .MuiSvgIcon-root {
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
  }
}

.AppReamazeModule {
  display: flex;
  color: #333;
}
